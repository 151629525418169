var TeethSearch = function(app) {
	this.app = app;

	this.opened = false;
	this.el = $(".search");
	this.state = this.app.config.searchState;

	this.init();
};

TeethSearch.prototype.init = function() {
	this.el.find(".search__icon").click(this.btnClick.bind(this));
	this.el.find(".search__input").keyup(this.keyUp.bind(this));
};

TeethSearch.prototype.btnClick = function() {
	if (this.opened) {
		this.search();
	}
	else {
		this.open();
	}
};

TeethSearch.prototype.keyUp = function(e) {
	if (e.keyCode === 13) {
		this.search();
	}

	if (e.keyCode === 27) {
		this.close();
	}
};

TeethSearch.prototype.open = function() {
	this.opened = true;
	this.el.addClass("search--open");

	this.el.find(".search__input").focus();

	this.app.async(function() {
		$(document).click(this.shouldClose.bind(this));
	}.bind(this), 1);
};

TeethSearch.prototype.close = function() {
	this.opened = false;
	this.el.removeClass("search--open");

	this.el.find(".search__input").blur();

	$(document).unbind("click");
};

TeethSearch.prototype.shouldClose = function(e) {
	if ($(e.target).hasClass("search__input") ||
		$(e.target).hasClass("search__icon")) {
		return false;
	}
	else {
		this.close();
	}
};

TeethSearch.prototype.search = function() {
	var searchEl 	= this.el.find(".search__input"),
		val 		= searchEl.val();

	if(val.trim() !== '') {
		var state 		= this.state;
		state.absolute 	= this.app.config.urls.site + "?/search/q=" + encodeURI(val);
		state.slug 		= "/search/?q=" + encodeURI(val);
		state.search 	= val;

		history.pushState(state, this.app._generateTitle(state), this.app.config.urls.site + state.slug);
	    document.title = this.app._generateTitle(state);

	    this.app._fireContent(state);

	}

	this.close();
};
