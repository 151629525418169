var TeethArticle = function(app) {
  this.app = app;
};

TeethArticle.prototype.loop = function() {
  var self = this,
      windH = $(window).height(),
      scrollT = $(window).scrollTop(),
      elemVars = {
        perc: 0,
        elem: null,
        id: 0,
        name: '',
        key: -1
      },
      navH = $("nav").height();

  var tempVars = false;

  $(".article--not-featured").each(function(i, e) {


    tempVars = self._calculatePosition(this, windH, scrollT);

    var item = $(".post-sidebar__item--" + tempVars.id),
        bar = item.find(".post-sidebar__link__percentage__bar"),
        left = $(this).find(".article__left");

    if (tempVars.perc < 0) {
      $(this).addClass("article--future");
      $(this).removeClass("article--present article--past");

      if(!bar.hasClass('zero')) {
        bar.addClass('zero');
        bar.css({
          'transform': 'scale(1, 0)'
        });
      }
    }
    else if (tempVars.perc > 1) {
      $(this).addClass("article--past");
      $(this).removeClass("article--future article--present");

      if(!bar.hasClass('complete')) {
        bar.addClass('complete');
        bar.removeClass('zero');
        bar.css({
          'transform': 'scale(1, 1)'
        });
      }
    }
    else {
      if(tempVars.btm - (windH / 2) > 0) {

        if(tempVars.top - ((windH / 2) - navH) > 0) {
          $(this).addClass("article--present");
          $(this).removeClass("article--future article--past");
        }
        else {
          $(this).addClass("article--future");
          $(this).removeClass("article--present article--past");
        }
      }
      else {
        $(this).addClass("article--past");
        $(this).removeClass("article--future article--present");
      }

      bar.removeClass('complete zero');

      if(!item.hasClass("post-sidebar__item--active")) {
        $(".post-sidebar__item--active").removeClass("post-sidebar__item--active");
        item.addClass("post-sidebar__item--active");

        var itemRect = item.get(0).getBoundingClientRect();

        // $(".sidebar").animate({
        //   scrollTop : $(".sidebar").get(0).scrollTop + itemRect.top - $(".nav-bar--top").height()
        // }, 250);

        $(".sidebar").animate({
          scrollTop : $(".sidebar").get(0).scrollTop + itemRect.top
        }, 250);

        var stateObj = item.find(".post-sidebar__link").data("state");

        if(window.location.pathname.indexOf(stateObj.slug) < 0) {
          history.replaceState(stateObj, self.app._generateTitle(stateObj), self.app.config.urls.site + stateObj.slug);
          document.title = self.app._generateTitle(stateObj);
        }
      }

      bar.css({
        'transform': 'scale(1, '+ tempVars.perc +')'
      });
    }
  });

  if(!this._articlesFinished) {
    elemVars = self._calculatePosition($(".article--not-featured").last().get(0), windH, scrollT);


    if(elemVars.perc > 0.2) {
      var nextLink = $(".post-sidebar__item--" + elemVars.id).next().find(".post-sidebar__link");

      if(nextLink.length) {
        this._addArticleOn(nextLink.data("state"));
      }
      else {
        this._articlesFinished = true;
        // Reveal footer
        $("footer").addClass("visible");
      }
    }
  }

  var footerRect = (this._articlesFinished) ? $("footer").get(0).getBoundingClientRect() : 0 ;

  var pixelsOn = footerRect.top - windH;

  if(pixelsOn < 0) {
    $(".sidebar-fixed").css({
      transform: "translateY(" + pixelsOn + "px)"
    });
  }
  else {
    $(".sidebar-fixed").css({
      transform: "translateY(0px)"
    });
  }
};

TeethArticle.prototype._calculatePosition = function(el, windH, scrollT) {
  var elem = $(el);

  var elemPos = el.getBoundingClientRect();

  var perc = this.app.mapRange((elemPos.top - (windH / 2)), 0, (elemPos.height * -1), 0, 1);

  var pixels = perc * elemPos.height;

  var obj = {
    el    : el,
    perc  : perc,
    btm   : elemPos.height - pixels,
    top   : pixels,
    id    : elem.data('id'),
    name  : elem.data('name')
  };

  return obj;
};

TeethArticle.prototype._addArticleOn = function(state) {

  if(!this.app._articleTrans) {
    this.app._articleTrans = true;

    this.app._articleAjax = state;
    this.app._articleAjax.action = 'nextArticle';
    this.app._articleAjax.nonce = this.app.config.site.nonce;

    $.ajax({
      dataType: "json",
      url: this.app.config.urls.ajax,
      data: this.app._articleAjax,
      success: this._renderArticleOn.bind(this)
    });
  }
};

TeethArticle.prototype._renderArticleOn = function(data) {
  var self = this;

  $(".article--not-featured").last().after(data.content);

  this.app.async(function() {
    $(".article--not-featured").last().find(".actor").removeClass("trans--in");
    self.app.fire();
  }, 5);
};