var TeethStockists = function(app) {
  this.app = app;
  this.overlay = $(".locations");
  this.region = '';

  this.init();
};

TeethStockists.prototype.init = function() {
  $("#stockistOpen").click(this.open.bind(this));

  this.overlay.click(this.shouldClose.bind(this));

  this.overlay.find("a").click(this.linkRegion.bind(this));

  this.alreadySet();
};

TeethStockists.prototype.alreadySet = function() {
	var params = this.app.queryString();
  if(params.hasOwnProperty('region')) {
    this.setRegion(params.region);
  }
};

TeethStockists.prototype.open = function() {
  this.overlay.addClass("locations--show");
  $("html").css({overflow: "hidden"});
};

TeethStockists.prototype.close = function() {
  this.overlay.removeClass("locations--show");
  $("html").removeAttr("style");
};

TeethStockists.prototype.shouldClose = function(e) {
	if (e.target.tagName === 'LI' ||
		e.target.tagName === 'UL' ||
		e.target.tagName === 'A') {
		return false;
	}
	else {
		this.close();
	}
};

TeethStockists.prototype.linkRegion = function(e) {
	e.preventDefault();

	var curr = $(e.currentTarget);

	var state = this.app.config.state;
	state.absolute = curr.attr("href");
	state.slug = '/stockists/?region=' + curr.data("region")

	history.replaceState(this.app.config.state, this.app._generateTitle(this.app.config.state), curr.attr("href"));

  this.close();
  this.setRegion(curr.data("region"));
};

TeethStockists.prototype.setRegion = function(region) {
  if(this.region !== '') {
    $(".stockist").not("."+this.region).removeClass("stockist--hide");
  }

  if(region !== '') {
    $(".stockist").not("."+region).addClass("stockist--hide");
    var title = $('a[data-region="'+region+'"').html();    
  } else {
    var title = "Choose a Region";    
  }

  $(".page-container__region-select button span").html(title);

  this.region = region;
};


