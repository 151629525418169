var TeethObject = function(args) {
  this.config = args;
};

TeethObject.prototype.init = function() {
  // The setup is run once
  this.setup();

  // The fire method is fired after every ajax call
  this.fire();
};

/**
 * The setup method is called on the initial load
 * and sets up any first time variables that get
 * used subsequently through the site
 */
TeethObject.prototype.setup = function() {
  this._setVariables();
  this._setupHistory();
  this._setupHeadroom();
  this._singleEvents();
  this._checkSplash();
  this._searchInit();
};
  
  /**
   * @private
   *
   * The _setVariables method sets up the variables
   * that get used consisttently through the site
   */
  TeethObject.prototype._setVariables = function() {

    // This object holds all the properties needed for the ajax calls
    this.ajax = {
      // Path is incremented every ajax call
      path: 0,
      // This is the current state object
      old: this.config.state,
      // New inherits the new state object when a call is fired
      new: {},
      // Timestamp of the start of the ajax call
      start: 0,
      // This increments when:
      //  1) the data is loaded in an ajax call
      //  2) the fade out transitions have finished
      flag: 0,
      // A property to track whether its transitioning
      transitioning: false
    };

    // The length of time splash is displayed
    this.splashDelay = 2000;

    // Caches the transition event with vendor prefix
    this.transitionString = this.whichTransitionEvent();

    // The class that is used for the actor elements
    this.actorCls = '.actor';
    
    // Caches the variables that are used in the requestAnimationFrame
    this.scrollVars = {
      y: 0,
      ticking: false
    };

    // This object holds booleans of whether certain methods
    // need to get fired
    this.animationCache = {
      articleFollow: false
    };

    this.article = new TeethArticle(this);

    // The mobile menu variable
    this.menu = false;
  };
  
  /**
   * @private
   *
   * Reset variables, does kind of what it sounds like
   */
  TeethObject.prototype._resetVariables = function() {
    this._articleTrans = false;
    this._articlesFinished = false;
    this._articleAjax = false;

    this.stockists = null;

    if($(".locations").length) {
      this.stockists = new TeethStockists(this);
    }
  };

  /**
   * @private
   *
   * Sets up the binding for the history handling
   */
  TeethObject.prototype._setupHistory = function() {
    window.onpopstate = this._manageHistory.bind(this)
  };

  /**
   * @private
   *
   * The method that is called when pop state is fired.
   * This handles refiring the ajax calls for the previous content
   * in the history array
   */
  TeethObject.prototype._manageHistory = function(e) {
    // Manually sets the window title from the state object
    document.title = this._generateTitle(e.state);

    // Fire the content method from the newly popped state
    this._fireContent(e.state);
  };

  /**
   * @private
   *
   * This is the important method that takes a state object
   * and from that initiates the ajaxCall for the new page
   *
   * @param {Object:State}
   */
  TeethObject.prototype._fireContent = function(state) {
    // Transitioning begins here
    this.ajax.transitioning = true;

    // Sets the called state as the new state object
    this.ajax.new = state;

    // Adds some extra parameters for the actual php ajax call
    this.ajax.new.action = 'ajaxReplace';
    this.ajax.new.nonce = this.config.site.nonce;
    this.ajax.new.path = this.ajax.path;

    // Starts the timestamp at this current point
    this.ajax.start = this.timeNow();
    // Sets the initial timestamp flag to be the same as the start
    this.ajax.flag = this.ajax.start;

    // Fires in parallel getting the content and starting the
    // actual visual transition
    this._getContent();
    this._transOut();
  };

    /**
     * @private
     *
     * Fires the actual ajax call for the new content
     */
    TeethObject.prototype._getContent = function() {
      $.ajax({
        dataType: "json",
        url: this.config.urls.ajax,
        data: this.ajax.new,
        success: this._evaluateContent.bind(this)
      });
    };

    /**
     * @private
     *
     * This method gets called on the success from the ajax object
     * @param {Object} data The response from the ajax call
     */
    TeethObject.prototype._evaluateContent = function(data) {
      // If a timer was set (because the transition hasnt finished)
      // clear it first just in case
      clearTimeout(this.ajax.timer);

      // An extra check to see if this data returned is the data we
      // actually want and not an old ajax call
      if(data.query.path == this.ajax.path) {

        // If the flag no longer matches the start, the transition has finished
        if (this.ajax.start != this.ajax.flag) {
          this.config.state = this.ajax.new;
          this._replaceContent(data);
        }
        else {
          // Refire this exact method if the transition hasnt finished
          this.ajax.timer = setTimeout(function() {
            this._evaluateContent(data);
          }.bind(this), 50);
        }
      }
    };

    /**
     * @private
     *
     * Transitions the actor elements out and calls
     * the event attacher to figure out the end of the transition
     */
    TeethObject.prototype._transOut = function() {
      // Actors get the general transitioning rule added
      $(this.actorCls).addClass("trans--rule");

      // Reset menu
      this._toggleMenu(false);

      // Adds this 1 frame later just so the events get registered correct
      this.async(function() {
        this._actorsFinished(this._bookendTransOut.bind(this));

        // Actors get the transition out class
        $(this.actorCls).addClass("trans--out");
      }.bind(this), 1);
    };

    /**
     * @private
     *
     * Sets the flag when the transition out has finished
     * so the ajax content call can get notified
     */
    TeethObject.prototype._bookendTransOut = function() {
      this.ajax.flag = this.timeNow();
    };

    /**
     * @private
     *
     * This method takes the data from the successful ajax
     * call and replaces the content in the dom. It also 
     * alters certain classes and structural things
     */
    TeethObject.prototype._replaceContent = function(data) {
      // If the content returned is a dark page, switch the classes
      if(data.arguments.structure.dark) {
        $("html").addClass("site--dark");
      }
      else {
        $("html").removeClass("site--dark");
      }

      // Change data type so we could target their styles
      $("html").attr("data-type",data.arguments.state.type);
      
      // Replaces the content in the DOM
      $("#ajax-replace").html(data.content);

      // Remove all the active nav items classes
      $(".nav-items__item__link--active").removeClass("nav-items__item__link--active");

      // If there is a new nav active item add the class here
      if(data.arguments.structure.active) {
        $(".nav-items__item__link--" + data.arguments.structure.active).addClass("nav-items__item__link--active");
      }

      // Make sure its scrolled back to the top
      $("html,body").scrollTop(0);

      // Fire the fire method with the new content
      this.fire();

      // Fires the transition in method
      this._transIn();
    };

    /**
     * @private
     *
     * Essentially the flip of the transOut
     */
    TeethObject.prototype._transIn = function() {
      // Temporarily remove the transition rule so we can
      // safely remove the transition out rule
      $(this.actorCls).removeClass("trans--rule");
      this.async(function() {
        $(this.actorCls).removeClass("trans--out");
      }.bind(this), 1);

      // Handle the transition in shit
      this.async(function() {
        $(this.actorCls).addClass("trans--rule");
        this._actorsFinished(this._bookendTransIn.bind(this));
        $(this.actorCls).removeClass("trans--in");
      }.bind(this), 2);
    };

    /**
     * @private
     *
     * This method cleans up the transition in
     * portion of the ajax method
     */
    TeethObject.prototype._bookendTransIn = function() {
      this.ajax.transitioning = false;
      this.ajax.flag = this.timeNow();
    };

    /**
     * @private
     *
     * Adds event listeners for the transition end
     * on the actor elements and fires a callback
     * when theyre all finished
     * @param {Function}fnc The callback function
     */
    TeethObject.prototype._actorsFinished = function(fnc) {
      var len = $(this.actorCls).length,
          local = 0,
          self = this;

      $(this.actorCls).on(this.transitionString, function() {
        local++;

        if (local == len) {
          fnc();
        }
        $(this).off(self.transitionString);
      });
    }

  /**
   * @private
   *
   * Sets up the whole html tag as a headroom
   * element so that any children can benefit from
   * the headroom classes
   */
  TeethObject.prototype._setupHeadroom = function() {
    $("html").headroom({
      // vertical offset in px before element is first unpinned
      offset : 200,
      // scroll tolerance in px before state changes
      tolerance : 0,
      // or you can specify tolerance individually for up/down scroll
      tolerance : {
          up : 5,
          down : 0
      },
      // css classes to apply
      classes : {
          // when element is initialised
          initial : "bite",
          // when scrolling up
          pinned : "bite--off",
          // when scrolling down
          unpinned : "bite--on",
          // when above offset
          //top : "bite--na",
          // when below offset
          notTop : "bite--not-top",
          // when at bottom of scoll area
          bottom : "bite--bottom",
          // when not at bottom of scroll area
          notBottom : "bite--not-bottom"
      }
    });
  };

  TeethObject.prototype._parallaxInit = function(el) {

       var el = $(el).find(".advert-content"),
         el_top = el.offset().top,
         el_data_diff = ((window.scrollY - el_top) * -0.05);

        el.attr('data-top', el_top);

  };

  TeethObject.prototype._parallax = function() {
    if ($(".advert--full.advert--loaded").length) {

      $(".advert--full.advert--loaded").each( function() {

        var el = $(this).find(".advert-content");

        var windH = $(window).height();

        var el_top = el.offset().top,
          el_data_top = el.data("top"),
          el_bottom = el_top + el.height(),
          scroll_bottom = window.scrollY + windH,
          translate_val = (window.scrollY - el_data_top) * -0.1;

          if (el.hasClass("advert-content--video")) {
            $(this).find("video").css({
              top: translate_val
            });
          } else {
            el.css({
              'background-position': 'center ' + translate_val + 'px',
            });            
          }          

      });
    }
  };  
  
  TeethObject.prototype._toggleMenu = function(bool)
  {
    var self = this;

    if(typeof bool !== "undefined")
    {
      this.menu = bool ? false : true;
    }

    if(this.menu)
    {
      this.menu = false;
      $("html").removeClass("mobile-open");
    }
    else
    {
      this.menu = true;
      $("html").addClass("mobile-open");
    }
  }

  TeethObject.prototype._checkSplash = function() {
    // If the splash element exists
    if ($(".splash").length) {
      this.splashTimer = setTimeout(this._removeSplash.bind(this), this.splashDelay);
      $(".splash").click(this._removeSplash.bind(this));
    }
  };

  TeethObject.prototype._removeSplash = function() {
    clearTimeout(this.splashTimer);

    $(".splash").fadeOut(500, function() {
      $(this).remove();
    });
  };

  TeethObject.prototype._searchInit = function() {
    this.search = new TeethSearch(this);
  };

  /**
   * @private
   *
   * This method sets any events that only need to get
   * set once on initial load
   */
  TeethObject.prototype._singleEvents = function() {
    var self = this;

    $(window).scroll(function(e) {
      self.scrollVars.y = window.scrollY;
      self._requestScrollTick();
    });

    // Bind the navicon to open the mobile menu
    $(".open-mobile-nav").unbind("click");
    $(".open-mobile-nav").click(function(e) {
      e.preventDefault();
      self._toggleMenu();
      return false;
    });    
  };

    /**
     * @private
     *
     * A method that allows the debouncing of the scroll
     * event, so the call stack doesn't get used up
     */ 
    TeethObject.prototype._requestScrollTick = function() {
      // If ticking is false that means the animation loop method
      // is freed up and ready to be called again
      if (!this.scrollVars.ticking) {
        requestAnimationFrame(this._animationLoop.bind(this));
      }

      this.scrollVars.ticking = true;
    };

    /**
     * @private
     *
     * Animation loop method that gets called from
     * requestAnimationFrame. This method does nothing
     * itself, but fires other methods if they're needed
     * on the page
     */
    TeethObject.prototype._animationLoop = function() {
      // If ajax is transitioning do not call any methods
      if(!this.ajax.transitioning) {

        // If we're on a article page, fire the article methods
        if (this.animationCache.articleFollow) {
          this._articleFollow();
        }

        this._lazyImage();
        this._parallax();
      }

      this.scrollVars.ticking = false;
    };

    TeethObject.prototype._articleFollow = function() {
      this.article.loop();
    };

    TeethObject.prototype._lazyImage = function() {
      var windH = $(window).height();
      var self = this;

      $(".lazy-image").not(".lazy-image--loading, .lazy-image--loaded").each(function(i,e) {
        var rect = this.getBoundingClientRect();

        if(rect.top > 0 && rect.top < windH) {
          self._lazyLoadImage(this);
        }
      });
    };

    TeethObject.prototype._lazyLoadImage = function(el) {
      $(el).addClass("lazy-image--loading");

      var url = $(el).data("url"),
          self = this;

      var img = new Image();
      img.src = url;
      img.onload = function() {
        $(el).find("img").attr("src", url);
        $(el).removeClass("lazy-image--loading");

        self.async(function() {
          $(el).addClass("lazy-image--loaded");
        }, 500);
      };
    };


TeethObject.prototype.fire = function() {
  this.ajax.path++;

  this._resetVariables();
  this._events();
  this._updateAnimationCache();
  this._shareButtons();
  this._lazyImage();

  this.async(function() {
    this._loadAdverts();
  }.bind(this), 100);
};
  
  TeethObject.prototype._events = function() {
    $("[data-ajax]").not(".ajax--bound").click(this._ajaxClick.bind(this));
    $("[data-ajax]").not(".ajax--bound").addClass("ajax--bound");

    $(".load-more .btn").click(this._loadMoreClick.bind(this));

    // $(".signup__button").unbind('click');
    // $(".signup__button").click(this._signupClick.bind(this));

    // $(".signup__input").unbind('keyup');
    // $(".signup__input").keyup(this._signupKeyup.bind(this));
  };

  TeethObject.prototype._ajaxClick = function(e) {
    if (e.button === 0) {
      e.preventDefault();

      if(!this.ajax.transitioning) {
        var stateObj = $(e.delegateTarget).data("state");

        this.commitState(stateObj);
      }
    }
  };

  TeethObject.prototype.commitState = function(state) {
    if(this.config.state != state) {

      ga('send', 'pageview', {'page': this.config.urls.site + state.slug, 'title': this._generateTitle(state)});

      history.pushState(state, this._generateTitle(state), this.config.urls.site + state.slug);
      document.title = this._generateTitle(state);

      this._fireContent(state);
    }
  }

  TeethObject.prototype._loadMoreClick = function(e) {
    var obj     = this.config.state;
    obj.nonce   = this.config.site.nonce;
    obj.action  = 'loadMore';
    
    var newObj = $.extend(obj, $(e.delegateTarget).data());

    $(".load-more .btn").addClass("btn--loading");

    $.ajax({
      dataType: "json",
      url: this.config.urls.ajax,
      data: obj,
      success: this._evaluateLoadMore.bind(this)
    });
  };

    TeethObject.prototype._evaluateLoadMore = function(data) {
      
      if(data.arguments.morePosts) {
        $(".load-more .btn").data("page", parseInt(data.arguments.loadPage) + 1);
      }
      else {
        $(".load-more").slideUp(250);
      }
      
      $(".home-container").last().append(data.content);

      this.async(function() {
        $(".post-item--hidden").removeClass("post-item--hidden");
      }.bind(this), 1);
    };

  TeethObject.prototype._updateAnimationCache = function() {

    this.animationCache.articleFollow = $(".article--not-featured").length ? true : false;
  };

  TeethObject.prototype._loadAdverts = function() {
    var self = this;

    $(".advert").not(".advert--loaded").each(function(i,e) {
      self._loadSingleAdvert(this);
    });
  };

  TeethObject.prototype._loadSingleAdvert = function(el) {
    var self        = this;

    var obj         = {};
    obj.nonce       = this.config.site.nonce;
    obj.action      = 'getAdvert';
    obj.size        = $(el).data("size");
    obj.placement   = $(el).data("placement");    

    $.ajax({
      dataType: "json",
      url: this.config.urls.ajax,
      data: obj,
      success: function(data) {

        if(data.noAdvert) {
          $(el).slideUp(250);
        }

        else {

          $(el).html(data.content);

          self.async(function() {
            var cont = $(el).find(".advert-content");
            cont.removeClass("advert-content--hidden");
            $(el).addClass("advert--loaded");
            if ($(el).hasClass("advert--full")) {

              //preload video
              var vid_src = cont.attr("data-vid");

              if (cont.hasClass("advert-content--video")) {

                var vid_block = '<video preload="auto" muted loop>'+
                                  '<source src="'+vid_src+'" type="video/mp4">'+
                                  'Your browser does not support the video tag.'+
                                '</video>';
                var vid = $.parseHTML(vid_block);

                $(el).find(".advert-content").append(vid);

                //keep checking whether the video is ready
                this.loadTimer = setInterval( function() {

                  var vid = cont.find("video");

                  if (vid.prop('readyState') === 4) {
                    
                    //play video when it's ready
                    if (vid.get(0).paused) {
                      vid.get(0).play();  
                    }

                    //stop checking if loaded
                    clearInterval(this.loadTimer);

                  }

                }, 20);

              }

              //init parallax
              self._parallaxInit(el);

            }

          }, 20);

        }

      }

    });
  };

  // TeethObject.prototype._signupKeyup = function(e) {
  //   if(e.keyCode === 13) {
  //     this._signupClick(e);
  //   }
  // };

  // TeethObject.prototype._signupClick = function(e) {
  //   var parentSignup  = $(e.target).parents(".signup");

  //   if(!parentSignup.hasClass("signup--disabled")) {
  //     $(".signup").addClass("signup--disabled");

  //     var inputSignup   = parentSignup.find(".signup__input"),
  //         emailVal      = inputSignup.val();

  //     if(this.validateEmail(inputSignup.val())) {
  //       this._fireSignup(emailVal);
  //     }
  //     else {
  //       this._signupMessage("error", "Not a valid email");
  //       $(".signup").removeClass("signup--disabled");
  //     }
  //   }
  // };

  // TeethObject.prototype._fireSignup = function(val) {
  //   var self    = this;

  //   var obj     = {};
  //   obj.nonce   = this.config.site.nonce;
  //   obj.action  = 'addEmail';
  //   obj.email   = val;

  //   $.ajax({
  //     dataType: "json",
  //     url: this.config.urls.ajax,
  //     data: obj,
  //     success: this._signupValidate.bind(this)
  //   });
  // };

  // TeethObject.prototype._signupMessage = function(status, msg) {
  //   clearTimeout(this.signupTimer);

  //   $(".signup").addClass("signup--msg-"+status + " signup--msg-show");
  //   $(".signup__message").text(msg);

  //   this.signupTimer = setTimeout(function() {
  //     $(".signup").removeClass("signup--msg-"+status+" signup--msg-show");
  //     $(".signup__message").text("");
  //   }, 2000);
  // };

  // TeethObject.prototype._signupValidate = function(data) {
  //   if(data.success) {
  //     this._signupMessage('success', data.message);
  //   }
  //   else {
  //     this._signupMessage('error', data.message);
  //   }
  //   $(".signup").removeClass("signup--disabled");
  // };

  TeethObject.prototype._shareButtons = function() {
    var self = this;

    $(".share-link").not(".event--bound").click(function(e) {
      e.preventDefault();

      if(!$(this).hasClass("share-link--pinterest")) {
        self.popupCenter($(this).attr("href"), "Share window", 400, 250);
      }
      else {
        self.pinit();
      }
    });

    $(".share-link").addClass("event--bound");
  };

TeethObject.prototype._generateTitle = function(obj) {
  return obj.title + " \u2014 " + this.config.site.name;
};

var app = new TeethObject(args); // Args declared in header.php

$(document).ready(function() {
  app.init();
});